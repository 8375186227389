<template>
  <el-dialog v-el-drag-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" :title="$t('common.select')"
    width="1000px" @close="$reset('form')" append-to-body class="adaptationModal faultCodeSelect">
    <el-table :data="tableData" style="width: 100%" border v-loading="loading" @select="selectHandle"
      @selectAll="selectHandle">
      <el-table-column type="selection" width="65" :label="$t('common.index')" class="checkBox"> </el-table-column>
      <el-table-column prop="code" :label="$t('faultTemplate.faultCode')" width="100" align="center"> </el-table-column>
      <el-table-column prop="desc" :label="$t('faultTemplate.faultType')"> </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{ $t('common.cancel') }}</el-button>
      <el-button type="primary" @click="saveHandle">{{ $t('common.save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      loading: false,
      selection: [],
    };
  },

  methods: {
    open(id) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.getList(id);
      });
    },

    getList(id) {
      this.loading = true;
      this.$http
        .get(`faults/${id}`)
        .then((res) => {
          this.tableData = res.data.faultSettings;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    selectHandle(selection) {
      this.selection = selection;
    },

    saveHandle() {
      let arr = [];
      this.selection.forEach((item) => {
        arr.push(item.code);
      });
      this.$emit("select", arr.join());
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
.faultCodeSelect tr th:first-child .cell,
.faultCodeSelect tr td:first-child .cell {
  text-align: center;
}
</style>
