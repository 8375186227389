<template>
  <div>
    <vm-table ref="vmTable" url="periodicInspectionBill" :filter.sync="search">
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.dayControlEdit.open()">
          {{ $t("common.add") }}
        </el-button>
        <div style="position: absolute;right: 32px;top: 0;color: #4d4d4d;font-size: 12px;">
          <span>{{ $t('dayWeekMonthManger.count') }}：</span>
          <span style="font-family: 'electronicFont';color: #0747fd;font-size: 40px;">{{ count }}</span>
          <!-- <span>台</span> -->
        </div>
      </template>
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('dayWeekMonthManger.status')">
          <el-select v-model="search.status" clearable>
            <el-option :value="1" :label="$t('common.submit')"></el-option>
            <el-option :value="0" :label="$t('common.notSubmit')"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('dayWeekMonthManger.isAbnormal')">
          <el-select v-model="search.exceptional" clearable>
            <el-option :value="true" :label="$t('dayWeekMonthManger.abnormal')"></el-option>
            <el-option :value="false" :label="$t('dayWeekMonthManger.notAbnormal')"></el-option>
          </el-select>
        </vm-search>
      </template>
      <el-table-column prop="billNo" :label="$t('dayWeekMonthManger.number')" width="120" align="center">
        <template slot-scope="scope">
          <router-link :to="'/dayControl/' + scope.row.id" target="_blank">{{
            scope.row.billNo }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorNO" :label="$t('dayWeekMonthManger.elevatorNo')" width="120" align="center"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="elevatorName" :label="$t('dayWeekMonthManger.elevatorName')"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="useUnitName" :label="$t('dayWeekMonthManger.useUnit')"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="status" :label="$t('dayWeekMonthManger.status')" width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 0">{{ $t("common.notSubmit") }}</span>
          <span v-else-if="scope.row.status === 1">{{ $t("common.submit") }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="exceptionCount" :label="$t('dayWeekMonthManger.controlResult')" width="130" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="!scope.row.exceptionCount">{{ $t('dayWeekMonthManger.notAbnormal') }}</el-tag>
          <el-tag type="danger" v-else>{{ scope.row.exceptionCount + $t('dayWeekMonthManger.anomalies') }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$t('dayWeekMonthManger.controlDate')" align="center" width="120"
        prop="startDate"></el-table-column>
      <el-table-column prop="createTime" :label="$t('dayWeekMonthManger.createTime')" width="140"
        align="center"></el-table-column>
      <el-table-column prop="creator" :label="$t('dayWeekMonthManger.creator')" width="100" align="center"
        show-overflow-tooltip></el-table-column>
      <el-table-column :label="$t('dayWeekMonthManger.operate')" width="140" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text">
            <router-link :to="'/dayControlReport/' + scope.row.id" target="_blank">{{ $t('dayWeekMonthManger.view')
              }}</router-link>
          </el-button>
          <el-button type="text" @click="$refs.dayControlEdit.open(scope.row.id)">{{ $t("dayWeekMonthManger.edit")
            }}</el-button>
          <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">{{
            $t("dayWeekMonthManger.delete")
          }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <DayControlEdit ref="dayControlEdit" @save-success="getList(1)"></DayControlEdit>
  </div>
</template>

<script>
import DayControlEdit from "./dayControlEdit.vue";
export default {
  components: { DayControlEdit },
  data() {
    return {
      search: {
        filter: "",
        status: "",
        periodicType: 1,
        exceptional: "",
      },
      count: 0
    };
  },

  mounted() {
    this.getList(1);
    this.getNumber();
  },

  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },

    getNumber() {
      this.$http.get("periodicInspectionBill/count/auto").then(res => {
        this.count = res.data;
      });
    },

    // 删除
    handleDelete(row) {
      this.$confirm(this.$t("dayWeekMonthManger.tip.confirmDelete"), this.$t("common.tips"), {
        confirmButtonText: this.$t("dayWeekMonthManger.ok"),
        cancelButtonText: this.$t("dayWeekMonthManger.cancel"),
        type: "warning",
      }).then(() => {
        this.$http
          .delete("periodicInspectionBill", row.id)
          .then(() => {
            this.getList(-1);
            this.$message.success(this.$t("dayWeekMonthManger.tip.deleteSuccess"));
          })
          .catch(() => {
            this.$message.error(this.$t("dayWeekMonthManger.tip.deleteError"));
          });
      });
    },
  },
};
</script>