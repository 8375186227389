<template>
  <el-dialog v-el-drag-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" :title="title" width="1100px"
    class="adaptationModal" v-loading="contentLoading" @close="$reset('form')">
    <el-form ref="form" :model="model" label-width="120px" :rules="ruleValidate">
      <el-row>
        <el-col :span="12" v-if="model.id">
          <el-form-item :label="$t('dayWeekMonthManger.number')">
            <el-input v-model="model.billNo" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('dayWeekMonthManger.dispatchCycle')" prop="date">
            <el-date-picker :picker-options="pickerOptions" style="width: 100%" v-model="model.date" type="month"
              value-format="yyyy-MM" :placeholder="$t('common.pleaseSelect')"
              :format="model.startDate + $t('dayWeekMonthManger.to') + model.endDate" @change="dateChange">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('dayWeekMonthManger.elevatorName')" prop="elevatorName">
            <el-input v-model="model.elevatorName" :placeholder="$t('common.pleaseSelect')" readonly>
              <el-button slot="append" @click="$refs.elevatorSelect.open()" icon="el-icon-search"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-table v-loading="loading" border stripe :data="model.results" style="width: 100%">
          <el-table-column type="index" width="65" :label="$t('common.index')" align="center"> </el-table-column>
          <el-table-column :label="$t('dayWeekMonthManger.type')" prop="itemType" width="140"> </el-table-column>
          <el-table-column :label="$t('dayWeekMonthManger.dispatchItems')" prop="itemName"> </el-table-column>
          <el-table-column :label="$t('dayWeekMonthManger.dispatchResult')" width="180" prop="itemValue">
            <template slot-scope="scope">
              <el-radio-group v-if="!scope.row.isAutomatic" v-model="scope.row.itemValue">
                <el-radio label="未见异常">未见异常</el-radio>
                <el-radio label="异常">异常</el-radio>
              </el-radio-group>
              <span v-else>{{ scope.row.itemValue }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('dayWeekMonthManger.handleResult')" width="280">
            <template slot-scope="scope">
              <el-input v-if="scope.row.itemType !== '故障' && scope.row.itemType !== '告警'"
                v-model="scope.row.handleResult" type="textarea" autosize
                :placeholder="$t('dayWeekMonthManger.tip.handleResult')"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <div style="margin: 8px 0">{{ $t("dayWeekMonthManger.mainProblem") }}</div>
      <el-input type="textarea" :placeholder="$t('common.pleaseEnter')" autosize v-model="model.mainProblem"></el-input>

      <div style="margin: 8px 0">{{ $t("dayWeekMonthManger.rectification1") }}</div>
      <el-input type="textarea" :placeholder="$t('common.pleaseEnter')" autosize
        v-model="model.rectification"></el-input>

      <div style="margin: 8px 0">{{ $t("dayWeekMonthManger.unresolvedProblem") }}</div>
      <el-input type="textarea" :placeholder="$t('common.pleaseEnter')" autosize
        v-model="model.unresolvedProblem"></el-input>

      <div style="margin: 8px 0">{{ $t("dayWeekMonthManger.scheduleContent") }}</div>
      <el-input type="textarea" :placeholder="$t('common.pleaseEnter')" autosize
        v-model="model.scheduleContent"></el-input>

      <div style="margin: 8px 0">{{ $t("dayWeekMonthManger.safetyContent") }}</div>
      <el-input type="textarea" :placeholder="$t('common.pleaseEnter')" autosize
        v-model="model.safetyContent"></el-input>

      <div style="margin: 8px 0">{{ $t("dayWeekMonthManger.precaution1") }}</div>
      <el-input type="textarea" :placeholder="$t('common.pleaseEnter')" autosize v-model="model.precaution"></el-input>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false"> {{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleSubmit">{{ $t("common.save") }}</el-button>
    </span>
    <elevator-select ref="elevatorSelect" @select="onElevatorSelect"></elevator-select>
  </el-dialog>
</template>

<script>
import ElevatorSelect from "@/views/elevator/ElevatorSelect.vue";
export default {
  components: { ElevatorSelect },
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      contentLoading: false,
      loading: false,
      model: {
        periodicType: 30,
        elevatorId: "",
        elevatorName: "",
        results: [],
        id: 0,
        mainProblem: "",
        rectification: "",
        unresolvedProblem: "",
        scheduleContent: "",
        safetyContent: "",
        precaution: "",
        startDate: "",
        endDate: "",
        date: "",
      },
      ruleValidate: {
        date: [{ required: true, message: this.$t("common.pleaseSelect") }],
        elevatorName: [{ required: true, message: this.$t("common.pleaseSelect"), trigger: "change" }],
      },
      pickerOptions: {
        disabledDate(time) {
          if (time.getFullYear() > new Date().getFullYear()) {
            return true;
          }
          if (time.getFullYear() == new Date().getFullYear()) {
            return new Date().getMonth() <= time.getMonth();
          }
        },
      },
    };
  },

  computed: {
    title() {
      return this.model.id ? this.$t("common.edit") : this.$t("common.add");
    },
  },

  methods: {
    open(id) {
      this.dialogVisible = true;
      this.model.id = id;
      if (id) {
        this.getData(id);
      }
    },

    // 月调度明细
    getData(id) {
      this.contentLoading = true;
      this.$http
        .get(`periodicInspectionBill/${id}`)
        .then((res) => {
          this.contentLoading = false;
          this.model = res.data;
          // 这样赋值可以解决时间回显不能修改的bug
          this.$set(this.model, "date", this.$moment(this.model.startDate).format("YYYY-MM"));
        })
        .catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
    },

    // 选择月份
    dateChange(val) {
      if (val) {
        this.model.startDate = this.$moment(this.model.date).startOf("month").format("YYYY-MM-DD");
        this.model.endDate = this.$moment(this.model.date).endOf("month").format("YYYY-MM-DD");
        if (this.model.elevatorId) {
          this.getMonthData();
        }
      }
    },

    // 选择电梯
    onElevatorSelect(row) {
      this.model.elevatorName = row.name;
      this.model.elevatorId = row.id;
      if (this.model.date) {
        this.getMonthData();
      }
    },

    // 获取调度项目
    getMonthData() {
      let params = {
        periodicType: 30,
        elevatorId: this.model.elevatorId,
        startDate: this.model.startDate,
        endDate: this.model.endDate,
      };
      this.loading = true;
      this.$http
        .post("periodicInspectionBill/result", null, params)
        .then((res) => {
          this.loading = false;
          this.model.results = res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
    },

    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          this.$http
            .save("periodicInspectionBill", this.model)
            .then(() => {
              this.$emit("save-success");
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$message.success(this.$t("common.tip.saveSuccess"));
            })
            .catch((error) => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
        } else {
          this.$message.error(this.$t("common.tip.submitError") + "!");
        }
      });
    },
  },
};
</script>

<style>
.adaptationModal .el-radio {
  margin-right: 10px;
}
</style>
