<template>
  <el-dialog v-el-drag-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" append-to-body
    :title="$t('common.add')" width="600px" @close="$reset('form')">
    <el-form ref="form" :model="model" label-width="100px" :rules="ruleValidate">
      <!-- <el-form-item label="项目种类" prop="itemCategory">
        <el-select v-model="model.itemCategory" :placeholder="$t('common.pleaseSelect')" disabled style="width: 100%;">
          <el-option label="自定义" :value="10"></el-option>
          <el-option label="核心部件" :value="20"></el-option>
          <el-option label="内置项" :value="30"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item :label="$t('dayWeekMonthManger.projectType')" prop="itemType">
        <el-input v-model="model.itemType" :placeholder="$t('common.pleaseEnter')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('dayWeekMonthManger.projectName')" prop="itemName">
        <el-input v-model="model.itemName" :placeholder="$t('common.pleaseEnter')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false"> 取消</el-button>
      <el-button type="primary" @click="saveHandle">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      model: {
        itemCategory: 10,
        itemType: "",
        itemName: "",
      },
      ruleValidate: {
        itemType: [{ required: true, message: this.$t("common.pleaseEnter"), trigger: "blur" }],
        itemName: [{ required: true, message: this.$t("common.pleaseEnter"), trigger: "blur" }],
      },
    };
  },

  methods: {
    open() {
      this.dialogVisible = true;
    },

    // 保存
    saveHandle() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("save-success", this.model);
          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>
