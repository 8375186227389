<template>
  <div class="checkTemplateEdit">
    <el-button type="primary" style="margin-bottom: 10px" @click="$refs.addItem.open()">{{ $t("common.add")
      }}</el-button>
    <el-table :data="weekTableData" style="width: 100%" row-key="key" border default-expand-all
      :tree-props="{ children: 'items' }">
      <el-table-column type="index" align="center" width="65" :label="$t('common.index')"></el-table-column>
      <el-table-column prop="itemType" :label="$t('dayWeekMonthManger.type')" width="140"> </el-table-column>
      <el-table-column prop="itemName" :label="$t('dayWeekMonthManger.checkItems')"> </el-table-column>
      <el-table-column :label="$t('dayWeekMonthManger.operate')" width="200">
        <template slot-scope="scope">
          <el-button type="text" :disabled="scope.row.itemCategory !== 10" @click="deleteHandle(scope.row)">{{
            $t("common.delete") }}</el-button>
          <el-button type="text" @click="hideHandle(scope.row)">{{ $t("common.hide") }}</el-button>
          <el-button type="text" :disabled="isMoveUp(scope.row)" @click="moveUpHandle(scope.row)">{{ $t("common.moveUp")
            }}</el-button>
          <el-button type="text" :disabled="isMoveDown(scope.row)" @click="moveDownHandle(scope.row)">{{
            $t("common.moveDown") }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-divider content-position="left">{{ $t("dayWeekMonthManger.hideItems") }}</el-divider>
      <el-button type="primary" style="margin-bottom: 10px" @click="showAllItems">{{ $t("dayWeekMonthManger.showAll")
        }}</el-button>
      <el-table :data="hideItems" style="width: 100%" border>
        <el-table-column type="index" align="center" width="65" :label="$t('common.index')"></el-table-column>
        <el-table-column prop="itemTypeCopy" :label="$t('dayWeekMonthManger.type')" width="140"> </el-table-column>
        <el-table-column prop="itemName" :label="$t('dayWeekMonthManger.checkItems')"> </el-table-column>
        <el-table-column :label="$t('dayWeekMonthManger.operate')" width="80" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="showHandle(scope.$index, scope.row)">{{ $t('dayWeekMonthManger.show')
              }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <AddItem ref="addItem" @save-success="addItem"></AddItem>
  </div>
</template>

<script>
import AddItem from "./addItem.vue";
export default {
  components: { AddItem },
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hideData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  watch: {
    tableData: {
      handler(value) {
        this.weekTableData = value;
        this.dataChange();
      },
    },
    hideData: {
      handler(value) {
        this.hideItems = value;
        this.dataChange();
      },
    },
  },

  data() {
    return {
      hideItems: [],
      weekTableData: [],
    };
  },

  computed: {
    isMoveUp() {
      return function (row) {
        if (Object.prototype.hasOwnProperty.call(row, "items")) {
          let index = this.weekTableData.findIndex((item) => item.itemType == row.itemType);
          return index == 0;
        } else {
          let parent = this.weekTableData.filter((item) => item.itemType == row.itemTypeCopy);
          let index = parent[0].items.findIndex((item) => item.itemName == row.itemName);
          return index == 0;
        }
      };
    },

    isMoveDown() {
      return function (row) {
        if (Object.prototype.hasOwnProperty.call(row, "items")) {
          let index = this.weekTableData.findIndex((item) => item.itemType == row.itemType);
          return index == this.weekTableData.length - 1;
        } else {
          let parent = this.weekTableData.filter((item) => item.itemType == row.itemTypeCopy);
          let index = parent[0].items.findIndex((item) => item.itemName == row.itemName);
          return index == parent[0].items.length - 1;
        }
      };
    },
  },

  methods: {
    // 新增
    addItem(data) {
      let arr = this.weekTableData.filter((item) => item.itemType == data.itemType);
      let obj = {
        periodicType: 7,
        itemCategory: 10,
        key: 1 + data.itemType + data.itemName,
        itemTypeCopy: data.itemType,
        itemType: "",
        itemName: data.itemName,
        rowIndex: null,
      };
      if (arr.length) {
        arr[0].items.push(obj);
      } else {
        let item = {
          periodicType: 7,
          itemType: data.itemType,
          key: 1 + data.itemType + data.itemType,
          items: [obj],
        };
        this.weekTableData.push(item);
      }
    },
    // 删除
    deleteHandle(row) {
      this.$confirm(this.$t("dayWeekMonthManger.tip.confirmDelete"), this.$t("common.tips"), {
        confirmButtonText: this.$t("dayWeekMonthManger.ok"),
        cancelButtonText: this.$t("dayWeekMonthManger.cancel"),
        type: "warning",
      }).then(() => {
        if (Object.prototype.hasOwnProperty.call(row, "items")) {
          // 删除大类
          this.weekTableData = this.weekTableData.filter((item) => item.itemType !== row.itemType);
        } else {
          // 删除小类
          let parent = this.weekTableData.filter((item) => item.itemType == row.itemTypeCopy);
          let deleteItemIndex = parent[0].items.findIndex((item) => item.itemName == row.itemName);
          parent[0].items.splice(deleteItemIndex, 1);
          if (parent[0].items.length == 0) {
            let parentIndex = this.weekTableData.findIndex((item) => item.itemType == row.itemTypeCopy);
            this.weekTableData.splice(parentIndex, 1);
          }
        }
        this.dataChange();
      });
    },

    hideHandle(row) {
      if (Object.prototype.hasOwnProperty.call(row, "items")) {
        // 隐藏大类
        let index = this.weekTableData.findIndex((item) => item.itemType == row.itemType);
        let hideItem = this.weekTableData.splice(index, 1);
        hideItem[0].items.forEach((item) => {
          this.hideItems.push(item);
        });
      } else {
        let index = this.weekTableData.findIndex((item) => item.itemType == row.itemTypeCopy);
        let parentItems = this.weekTableData[index].items;
        let indexChild = parentItems.findIndex((item) => item.itemName == row.itemName);
        let hideItem = this.weekTableData[index].items.splice(indexChild, 1);
        hideItem.forEach((item) => {
          this.hideItems.push(item);
        });
        if (!this.weekTableData[index].items.length) {
          this.weekTableData.splice(index, 1);
        }
      }
      this.dataChange();
    },

    // 上移
    moveUpHandle(row) {
      if (Object.prototype.hasOwnProperty.call(row, "items")) {
        // 上移大类
        let index = this.weekTableData.findIndex((item) => item.itemType == row.itemType);
        this.weekTableData.splice(index - 1, 1, this.weekTableData.splice(index, 1, this.weekTableData[index - 1])[0]);
      } else {
        // 上移小类
        let index = this.weekTableData.findIndex((item) => item.itemType == row.itemTypeCopy);
        let parentItems = this.weekTableData[index].items;
        let indexChild = parentItems.findIndex((item) => item.itemName == row.itemName);
        parentItems.splice(indexChild - 1, 1, parentItems.splice(indexChild, 1, parentItems[indexChild - 1])[0]);
      }
      this.dataChange();
    },

    // 下移
    moveDownHandle(row) {
      if (Object.prototype.hasOwnProperty.call(row, "items")) {
        // 下移大类
        let index = this.weekTableData.findIndex((item) => item.itemType == row.itemType);
        this.weekTableData.splice(index + 1, 1, this.weekTableData.splice(index, 1, this.weekTableData[index + 1])[0]);
      } else {
        // 下移小类
        let index = this.weekTableData.findIndex((item) => item.itemType == row.itemTypeCopy);
        let parentItems = this.weekTableData[index].items;
        let indexChild = parentItems.findIndex((item) => item.itemName == row.itemName);
        parentItems.splice(indexChild + 1, 1, parentItems.splice(indexChild, 1, parentItems[indexChild + 1])[0]);
      }
      this.dataChange();
    },

    // 显示
    showHandle(index, row) {
      row.rowIndex = null;
      if (this.weekTableData.length) {
        let arr = this.weekTableData.filter((item) => item.itemType == row.itemTypeCopy);
        if (!arr.length) {
          let each = {
            periodicType: row.periodicType,
            itemType: row.itemTypeCopy,
            items: [row],
            key: row.periodicType + row.itemType + row.itemType,
          };
          this.weekTableData.push(each);
        } else {
          let targetIndex = this.weekTableData.findIndex((item) => item.itemType == row.itemTypeCopy);
          arr[0].items.push(row);
          this.$set(this.weekTableData, targetIndex, arr[0]);
        }
      } else {
        let each = {
          periodicType: row.periodicType,
          itemType: row.itemTypeCopy,
          items: [row],
          key: row.periodicType + row.itemType + row.itemType,
        };
        this.weekTableData.push(each);
      }
      this.hideItems.splice(index, 1);
      this.dataChange();
    },

    // 数据变动 通知父组件
    dataChange() {
      let arr = [];
      this.weekTableData.forEach((item) => {
        item.items.forEach((i) => {
          arr.push(i);
        });
      });
      this.hideItems.forEach((item) => {
        item.rowIndex = -1;
        arr.push(item);
      });
      this.$emit("data", arr);
    },

    // 全部显示
    showAllItems() {
      this.hideItems.forEach((item) => {
        item.rowIndex = null;
        let arr = this.weekTableData.filter((i) => i.itemType == item.itemTypeCopy);
        let arrIndex = this.weekTableData.findIndex((i) => i.itemType == item.itemTypeCopy);
        if (arr.length) {
          arr[0].items.push(item);
          this.$set(this.weekTableData, arrIndex, arr[0]);
        } else {
          let each = {
            periodicType: item.periodicType,
            itemType: item.itemTypeCopy,
            items: [item],
            key: item.periodicType + item.itemType + item.itemType,
          };
          this.weekTableData.push(each);
        }
      });
      this.hideItems = [];
      this.dataChange();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-divider--horizontal {
  margin: 20px 0;
}
</style>

<style lang="scss">
.checkTemplateEdit {
  .el-input-group__append {
    text-align: center;
  }

  .el-table {
    .el-button--mini {
      padding: 0;
    }
  }
}
</style>
