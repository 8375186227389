<template>
  <el-dialog v-el-drag-dialog :close-on-click-modal="false" :visible.sync="dialogVisible"
    :title="model.id ? $t('common.edit') : $t('common.add')" width="1200px" class="adaptationModal checkTemplateEdit"
    @close="$reset('form')">
    <el-form ref="form" :model="model" label-width="110px" :rules="ruleValidate">
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('dayWeekMonthManger.templateNo')" v-if="model.id">
            <el-input v-model="model.templateNo" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('dayWeekMonthManger.templateName')" prop="templateName">
            <el-input v-model="model.templateName" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('dayWeekMonthManger.isDefault')">
            <el-select v-model="model.isDefault" :placeholder="$t('common.pleaseSelect')">
              <el-option :label="$t('common.yes')" :value="1"></el-option>
              <el-option :label="$t('common.no')" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-tabs v-model="activeName" type="border-card" v-loading="contentLoading">
      <el-tab-pane :label="$t('route.dayControl')" name="first">
        <dayContorl :tableData="dayTableData" :hideData="dayHideData" @data="getDayItems"></dayContorl>
      </el-tab-pane>
      <el-tab-pane :label="$t('route.weekCheck')" name="second">
        <weekCheck :tableData="weekTableData" :hideData="weekHideData" @data="getWeekItems"></weekCheck>
      </el-tab-pane>
      <el-tab-pane :label="$t('route.monthDispatch')" name="third">
        <monthDispatch :tableData="monthTableData" :hideData="monthHideData" @data="getMonthItems"></monthDispatch>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false"> {{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submitHandle">{{ $t("common.save") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dayContorl from "./dayContorl.vue";
import weekCheck from "./weekCheck.vue";
import monthDispatch from "./monthDispatch.vue";
export default {
  components: { dayContorl, weekCheck, monthDispatch },
  data() {
    return {
      contentLoading: false,
      dialogVisible: false,
      dayTableData: [],
      finalDayTableData: [],
      dayHideData: [],
      weekTableData: [], // 周排查项目
      finalWeekTableData: [],
      weekHideData: [],
      monthTableData: [], // 周排查项目
      finalMonthTableData: [],
      monthHideData: [],
      model: {
        id: "",
        templateName: "",
        templateNo: "",
        isDefault: 0,
        orgId: "",
        items: [],
      },
      activeName: "first",
      ruleValidate: {
        templateName: [{ required: true, message: this.$t("common.pleaseEnter"), trigger: "blur" }],
      },
      submitLoading: false,
    };
  },

  methods: {
    open(id) {
      this.dialogVisible = true;
      if (id) {
        this.getData(id);
      } else {
        this.getCheckList();
      }
    },

    // 根据Id获取明细
    getData(id) {
      this.contentLoading = true;
      this.$http
        .get(`periodicInspectionTemplate/${id}`)
        .then((res) => {
          this.model = res.data;
          this.dataProcess(res.data.types);
          this.contentLoading = false;
        })
        .catch(() => {
          this.contentLoading = false;
        });
    },

    // 处理明细
    dataProcess(arr) {
      arr.forEach((item) => {
        item.key = item.periodicType + item.itemType + item.itemType;
        item.items.forEach((each) => {
          each.itemTypeCopy = each.itemType;
          each.key = each.periodicType + each.itemType + each.itemName;
          each.itemType = "";
        });
      });
      // 过滤出rowIndex == -1的数据，及隐藏的项目
      let hideItems = [];
      arr.forEach((item) => {
        item.items.forEach((i) => {
          if (i.rowIndex == -1) {
            hideItems.push(i);
          }
        });
      });
      this.dayHideData = hideItems.filter((item) => item.periodicType == 1);
      this.weekHideData = hideItems.filter((item) => item.periodicType == 7);
      this.monthHideData = hideItems.filter((item) => item.periodicType == 30);

      let newArr = [];
      arr.forEach((item, index) => {
        newArr[index] = item;
        newArr[index].items = item.items.filter((item) => item.rowIndex !== -1);
      });
      newArr = newArr.filter((item) => item.items.length);
      this.dayTableData = newArr.filter((item) => item.periodicType == 1);
      this.weekTableData = newArr.filter((item) => item.periodicType == 7);
      this.monthTableData = newArr.filter((item) => item.periodicType == 30);
    },

    // 获取排查项目列表
    getCheckList() {
      this.contentLoading = true;
      this.$http
        .get("periodicInspectionItem/list")
        .then((res) => {
          if (res.data.length) {
            res.data.forEach((item) => {
              item.key = item.periodicType + item.itemType + item.itemType;
              item.items.forEach((each) => {
                each.itemTypeCopy = each.itemType;
                each.key = each.periodicType + each.itemType + each.itemName;
                each.itemType = "";
              });
            });
            this.dayTableData = res.data.filter((item) => item.periodicType == 1);
            this.weekTableData = res.data.filter((item) => item.periodicType == 7);
            this.monthTableData = res.data.filter((item) => item.periodicType == 30);
          }
          this.contentLoading = false;
        })
        .catch(() => {
          this.contentLoading = false;
        });
    },

    // 获取日管控项目
    getDayItems(data) {
      this.finalDayTableData = data;
    },

    // 获取周排查项目
    getWeekItems(data) {
      this.finalWeekTableData = data;
    },

    // 获取月调度项目
    getMonthItems(data) {
      this.finalMonthTableData = data;
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let arr = JSON.stringify([...this.finalDayTableData, ...this.finalWeekTableData, ...this.finalMonthTableData]);
          let newArr = JSON.parse(arr);
          newArr.forEach((item) => {
            item.itemType = item.itemTypeCopy;
          });
          this.model.items = newArr;
          // this.model.items = arr;
          // 校验故障代码是否为空
          // let arr = this.model.items.filter((item) => item.itemCategory == 20);
          // if (arr.length) {
          //   let result = arr.find((item) => !item.itemCondition);
          //   if (result) {
          //     this.$confirm("请选择故障代码", "错误", {
          //       type: "error",
          //     });
          //     return;
          //   }
          // }
          this.submitLoading = true;
          this.$http
            .save("periodicInspectionTemplate", this.model)
            .then(() => {
              this.$emit("save-success");
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$message.success(this.$t("common.tip.saveSuccess"));
            })
            .catch((error) => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
        }
      });
    },
  },
};
</script>
