<template>
  <el-dialog v-el-drag-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" :title="$t('common.select')"
    width="1200px" @close="$reset('form')" append-to-body top="6vh">
    <vm-table ref="vmTable" url="faults" :filter.sync="search">
      <template slot="adSearch">
        <vm-search :label="$t('dayWeekMonthManger.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="faultNO" :label="$t('faultTemplate.no')" width="140" align="center"></el-table-column>
      <el-table-column prop="companyName" :label="$t('elevator.manufactured')"></el-table-column>
      <el-table-column prop="name" :label="$t('dayWeekMonthManger.templateName')"></el-table-column>
      <el-table-column :label="$t('dayWeekMonthManger.operate')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="handleSelect(scope.row)">{{ $t("common.select") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <faultCodeSelect ref="faultCodeSelect" @select="getFaultCode"></faultCodeSelect>
  </el-dialog>
</template>

<script>
import faultCodeSelect from "./faultCodeSelect.vue";
export default {
  components: { faultCodeSelect },
  data() {
    return {
      dialogVisible: false,
      search: {
        filter: "",
      },
    };
  },

  methods: {
    open() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.getList(1);
      });
    },

    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },

    handleSelect(row) {
      this.$refs.faultCodeSelect.open(row.id);
    },

    getFaultCode(code) {
      this.$emit("save-success", code);
      this.dialogVisible = false;
    },
  },
};
</script>
