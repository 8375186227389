<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane v-if="dataAuth" :label="$t('route.dayControl')" name="first">
      <day-control-list></day-control-list>
    </el-tab-pane>
    <el-tab-pane v-if="dataAuth" :label="$t('route.weekCheck')" name="second">
      <week-check-list></week-check-list>
    </el-tab-pane>
    <el-tab-pane v-if="dataAuth" :label="$t('route.monthDispatch')" name="third">
      <month-dispatch-list></month-dispatch-list>
    </el-tab-pane>
    <el-tab-pane v-if="checkTemplatesAuth" :label="$t('route.checkTemplates')" name="four">
      <check-templates-list></check-templates-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import dayControlList from "@/views/dayWeekMonthManger/dayControl/dayControlList";
  import weekCheckList from "@/views/dayWeekMonthManger/weekCheck/weekCheckList";
  import monthDispatchList from "@/views/dayWeekMonthManger/monthDispatch/monthDispatchList";
  import checkTemplatesList from "@/views/dayWeekMonthManger/checkTemplates/checkTemplatesList";
  import auth from "@/util/auth";

  export default {
    components: { dayControlList, weekCheckList, monthDispatchList, checkTemplatesList },
    data() {
      return {
        dataAuth: this.$auth(823),
        checkTemplatesAuth: this.$auth(826),
        activeName: "first",
      };
    },
    mounted() {
      console.log(auth.getPlatform());
      if (this.dataAuth) {
        this.activeName = "first";
        return;
      }
      if (this.checkTemplatesAuth) {
        this.activeName = "four";
        return;
      }
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>
<style lang="scss" scoped></style>
