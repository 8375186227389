<template>
  <div>
    <vm-table ref="vmTable" url="periodicInspectionTemplate" :filter.sync="search">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.checkTemplatesEdit.open()">
          {{ $t("common.add") }}
        </el-button>
      </template>
      <el-table-column prop="templateNo" :label="$t('dayWeekMonthManger.templateNo')" width="120"
        align="center"></el-table-column>
      <el-table-column prop="templateName" :label="$t('dayWeekMonthManger.templateName')"
        show-overflow-tooltip></el-table-column>
      <el-table-column v-if="userType === 0" prop="orgName" :label="$t('dayWeekMonthManger.enterprise')"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="createTime" :label="$t('dayWeekMonthManger.createTime')" width="140"
        align="center"></el-table-column>
      <el-table-column prop="creator" :label="$t('dayWeekMonthManger.creator')" width="120"
        align="center"></el-table-column>
      <el-table-column prop="isDefault" :label="$t('dayWeekMonthManger.isDefault')" width="130" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="!scope.row.isDefault">{{ $t('common.yes') }}</el-tag>
          <el-tag type="info" v-else>{{ $t('common.no') }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$t('dayWeekMonthManger.operate')" width="100" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.checkTemplatesEdit.open(scope.row.id)">{{ $t("dayWeekMonthManger.edit")
            }}</el-button>
          <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">{{
            $t("dayWeekMonthManger.delete") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <check-templates-edit ref="checkTemplatesEdit" @save-success="getList(1)"></check-templates-edit>
  </div>
</template>

<script>
import CheckTemplatesEdit from "./checkTemplatesEdit.vue";
import auth from "@/util/auth";
export default {
  components: { CheckTemplatesEdit },
  data() {
    return {
      userType: auth.getUserType(),
      search: {
        filter: "",
      },
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },

    // 删除
    handleDelete(row) {
      this.$confirm(this.$t("dayWeekMonthManger.tip.confirmDelete"), this.$t("common.tips"), {
        confirmButtonText: this.$t("dayWeekMonthManger.ok"),
        cancelButtonText: this.$t("dayWeekMonthManger.cancel"),
        type: "warning",
      }).then(() => {
        this.$http
          .delete("periodicInspectionTemplate", row.id)
          .then(() => {
            this.getList(-1);
            this.$message.success(this.$t("dayWeekMonthManger.tip.deleteSuccess"));
          })
          .catch(() => {
            this.$message.error(this.$t("dayWeekMonthManger.tip.deleteError"));
          });
      });
    },
  },
};
</script>
